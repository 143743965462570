import { ContainerMaxWidth } from '@hypersay/ui';
import React from 'react';

import HowToHypersayMenu from '../../components/howToHypersayMenu';
import SEO from '../../components/seo';
import T from '../../components/T';
import LayoutBlog from '../../layouts/blog';

import { withPrefix } from 'gatsby';
import * as styles from './howtohypersay.module.scss';

const Education = () => (
  <LayoutBlog>
    <SEO
      title="How to Hypersay - Use cases - Education"
      description="Hypersay helps teachers and lecturers increase student participation and track their progress. And pitfalls!"
      keywords={[
        'live presentation',
        'interactive presentations',
        'live polling',
        'student quiz',
        'student quizzing',
        'student participation',
        'live courses',
        'lessons',
        'student engagement',
        'learning management',
        'student reponse system',
        'powerpoint',
        'google slides',
      ]}
    />
    <ContainerMaxWidth className={styles.container} maxWidth="1400px">
      <T tagName="h1" id="Hypersay for Education" />
      <T
        tagName="p"
        id="Our 180K users enjoy Hypersay for very different reasons, based on their professions. Below you can find several use cases."
      />
      <HowToHypersayMenu active="education" />
      <div className={styles.item}>
        <div className={styles.image}>
          <img
            src={withPrefix('/images/use-cases/attention-deficit.png')}
            alt="attention deficit"
          />
        </div>
        <div className={styles.text}>
          <T tagName="h2" id="Battling students attention span deficit" />
          <T
            tagName="p"
            id="Daydreaming and wandering is part of every student’s classroom experience.  But with the introduction of mobile devices, students attention span can become a scarce resource.  Hypersay allows the lecturer to bring back the attention of students to the primary focal point of the lesson by controlling which slides appear on their mobile devices.  When these slides are interactive (a poll, a question, a video) the battle for their attention is easily won."
          />
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.text}>
          <T tagName="h2" id="Even dropouts have data" />
          <T
            tagName="p"
            id="Before students dropout of University, they will leave loads of evidence as to why. Unfortunately for the University, this only becomes evident after they have left."
          />
          <T
            tagName="p"
            id="Hypersay helps identify early warning signals that can be used to prevent the dropout decision. Hypersay’s portfolio of interactive features offers opportunities to engage, challenge and stimulate university students during a lecture. Hypersay listens to the data produced during a lecture and produces a digital pulse for each student relative to all other students in the lecture.  This digital pulse depicts early warning signals for students in the wrong class or at risk of dropping out.  Hypersay permits Universities to view these early warning signs within weeks of the semester starting, rather than only when the student has exited."
          />
        </div>
        <div className={styles.image}>
          <img
            src={withPrefix('/images/use-cases/dropout.png')}
            alt="dropout"
          />
        </div>
      </div>
    </ContainerMaxWidth>
  </LayoutBlog>
);

export default Education;
